import React from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

interface Props {
  url: string;
  fileName: string;
}

const PdfDownloader: React.FC<Props> = ({ url, fileName }) => {
  const downloadPdf = async () => {
    try {
      const response = await axios.get(url, {
        responseType: 'blob', // Important to get the file as a Blob
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`, // Add any headers you need here
        },
      });

      const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', fileName); // Set the download attribute to the filename
      document.body.appendChild(link);
      link.click();

      // Clean up
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };

  return (
    <div className='shape text-center'>
      <button
        type='button'
        onClick={downloadPdf}
        style={{
          display: 'inline-block',
          cursor: 'pointer',
          padding: '10px',
          border: '0',
          backgroundColor: 'transparent',
          height: '3rem',
        }}
      >
        <FontAwesomeIcon icon={faDownload} size="1x" color="#000" /> Download PDF
      </button>
    </div>
  );
};

export default PdfDownloader;
