import React from 'react';
import '../../css/PLTable.css';

const staticData = {
  assets: {
    "2020": 1010610,
    "2021": 2316114,
    "2022": 6965959,
    "2023": 11976111
  },
  nonCurrentAssets: {
    "2020": 53811,
    "2021": 38045,
    "2022": 22945,
    "2023": 9094
  },
  propertyPlantEquipment: {
    "2020": 53811,
    "2021": 38045,
    "2022": 22945,
    "2023": 9094
  },
  currentAssets: {
    "2020": 956799,
    "2021": 2278069,
    "2022": 6943014,
    "2023": 11967017
  },
  relatedParties: {
    "2020": 147863,
    "2021": 214291,
    "2022": 214291,
    "2023": 607791
  },
  accountsReceivables: {
    "2020": 579259,
    "2021": 1746837,
    "2022": 6636767,
    "2023": 10491887
  },
  otherReceivables: {
    "2020": 0,
    "2021": 0,
    "2022": 0,
    "2023": 96733
  },
  cashBanks: {
    "2020": 229677,
    "2021": 316941,
    "2022": 91956,
    "2023": 770606
  },
  shareholdersEquityLiabilities: {
    "2020": 1010610,
    "2021": 2316114,
    "2022": 6965959,
    "2023": 11976111
  },
  totalShareholdersEquity: {
    "2020": 361401,
    "2021": 1036340,
    "2022": 6091690,
    "2023": 9900075
  },
  capitalCommonShares: {
    "2020": 100000,
    "2021": 100000,
    "2022": 100000,
    "2023": 100000
  },
  capitalReserves: {
    "2020": 26140,
    "2021": 26140,
    "2022": 26140,
    "2023": 26140
  },
  retainedEarnings: {
    "2020": 235261,
    "2021": 910200,
    "2022": 5965550,
    "2023": 9773935
  },
  profitBroughtForward: {
    "2020": -26140,
    "2021": 235261,
    "2022": 910200,
    "2023": 5965550
  },
  netProfitForTheYear: {
    "2020": 261401,
    "2021": 674939,
    "2022": 5055350,
    "2023": 3808385
  },
  dividends: {
    "2020": 0,
    "2021": 0,
    "2022": 0,
    "2023": 0
  },
  liabilities: {
    "2020": 649209,
    "2021": 1279774,
    "2022": 874269,
    "2023": 2076036
  },
  nonCurrentLiabilities: {
    "2020": 45041,
    "2021": 144827,
    "2022": 144827,
    "2023": 999976
  },
  loan: {
    "2020": 0,
    "2021": 0,
    "2022": 0,
    "2023": 999976
  },
  endOfServiceIndemnity: {
    "2020": 45041,
    "2021": 144827,
    "2022": 144827,
    "2023": 144827
  },
  currentLiabilities: {
    "2020": 604168,
    "2021": 1134947,
    "2022": 729442,
    "2023": 1076060
  },
  accountsPayables: {
    "2020": 147956,
    "2021": 431768,
    "2022": 359467,
    "2023": 641360
  },
  otherPayables: {
    "2020": 0,
    "2021": 0,
    "2022": 0,
    "2023": 82
  },
  relatedPartiesLiabilities: {
    "2020": 445711,
    "2021": 661790,
    "2022": 182141,
    "2023": 374
  },
  zakatPayables: {
    "2020": 10501,
    "2021": 41389,
    "2022": 187835,
    "2023": 434244
  },
};

const BalanceSheet: React.FC = () => {
  const years = ["2020", "2021", "2022", "2023"];

  const formatNumber = (value: any) => {
    return new Intl.NumberFormat('en-US').format(value.toFixed(2));
  };

  return (
    <div className="table-container">
      <h1>Balance Sheet</h1>
      <table className="balance-sheet-table">
        <thead>
          <tr>
            <th>Category</th>
            {years.map((year) => (
              <th key={year}>{year}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><b>ASSETS</b></td>
            {years.map((year) => (
              <td key={year}>{formatNumber(staticData.assets[year])}</td>
            ))}
          </tr>
          <tr>
            <td><b>NON-CURRENT ASSETS</b></td>
            {years.map((year) => (
              <td key={year}>{formatNumber(staticData.nonCurrentAssets[year])}</td>
            ))}
          </tr>
          <tr>
            <td>Property, Plant & Equipment</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(staticData.propertyPlantEquipment[year])}</td>
            ))}
          </tr>
          <tr>
            <td><b>CURRENT ASSETS</b></td>
            {years.map((year) => (
              <td key={year}>{formatNumber(staticData.currentAssets[year])}</td>
            ))}
          </tr>
          <tr>
            <td>Related Parties</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(staticData.relatedParties[year])}</td>
            ))}
          </tr>
          <tr>
            <td>Accounts Receivables</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(staticData.accountsReceivables[year])}</td>
            ))}
          </tr>
          <tr>
            <td>Other Receivables</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(staticData.otherReceivables[year])}</td>
            ))}
          </tr>
          <tr>
            <td>Cash & Banks</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(staticData.cashBanks[year])}</td>
            ))}
          </tr>
          <tr><td colSpan={years.length + 1}>&nbsp;</td></tr>
          <tr>
            <td><b>SHAREHOLDERS' EQUITY & LIABILITIES</b></td>
            {years.map((year) => (
              <td key={year}>{formatNumber(staticData.shareholdersEquityLiabilities[year])}</td>
            ))}
          </tr>
          <tr>
            <td><b>TOTAL SHAREHOLDERS' EQUITY</b></td>
            {years.map((year) => (
              <td key={year}>{formatNumber(staticData.totalShareholdersEquity[year])}</td>
            ))}
          </tr>
          <tr>
            <td>Capital - Common Shares</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(staticData.capitalCommonShares[year])}</td>
            ))}
          </tr>
          <tr>
            <td>Capital Reserves</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(staticData.capitalReserves[year])}</td>
            ))}
          </tr>
          <tr>
            <td>Retained Earnings</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(staticData.retainedEarnings[year])}</td>
            ))}
          </tr>
          <tr>
            <td>Profit Brought Forward</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(staticData.profitBroughtForward[year])}</td>
            ))}
          </tr>
          <tr>
            <td>Net Profit for the Year</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(staticData.netProfitForTheYear[year])}</td>
            ))}
          </tr>
          <tr>
            <td>Dividends</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(staticData.dividends[year])}</td>
            ))}
          </tr>
          <tr><td colSpan={years.length + 1}>&nbsp;</td></tr>
          <tr>
            <td><b>LIABILITIES</b></td>
            {years.map((year) => (
              <td key={year}>{formatNumber(staticData.liabilities[year])}</td>
            ))}
          </tr>
          <tr>
            <td><b>NON-CURRENT LIABILITIES</b></td>
            {years.map((year) => (
              <td key={year}>{formatNumber(staticData.nonCurrentLiabilities[year])}</td>
            ))}
          </tr>
          <tr>
            <td>Loan</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(staticData.loan[year])}</td>
            ))}
          </tr>
          <tr>
            <td>End of Service Indemnity</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(staticData.endOfServiceIndemnity[year])}</td>
            ))}
          </tr>
          <tr>
            <td><b>CURRENT LIABILITIES</b></td>
            {years.map((year) => (
              <td key={year}>{formatNumber(staticData.currentLiabilities[year])}</td>
            ))}
          </tr>
          <tr>
            <td>Accounts Payables</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(staticData.accountsPayables[year])}</td>
            ))}
          </tr>
          <tr>
            <td>Other Payables</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(staticData.otherPayables[year])}</td>
            ))}
          </tr>
          <tr>
            <td>Related Parties</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(staticData.relatedPartiesLiabilities[year])}</td>
            ))}
          </tr>
          <tr>
            <td>Zakat Payables</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(staticData.zakatPayables[year])}</td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BalanceSheet;
