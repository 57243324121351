import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Expenses({ workspaceId }) {
  const [expenses, setExpenses] = useState([]);

//   useEffect(() => {
//     // Fetch the revenues for the given workspace using Axios
//     const token = localStorage.getItem('token');
//     axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/sync-workspace/${workspaceId}`, {
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         }
//       })
//       .then((response) => {
//         setExpenses(response.data);
//       })
//       .catch((error) => {
//         console.error('Error fetching revenues:', error);
//       });
//   }, [workspaceId]);
  
  useEffect(() => {
    // Fetch the expenses for the given workspace using Axios
    const token = localStorage.getItem('token');
    // `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/workspaces/${workspaceId}/expenses`
    axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/workspaces/${workspaceId}/expenses`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      })
      .then((response) => {
        setExpenses(response.data);
      })
      .catch((error) => {
        console.error('Error fetching expenses:', error);
      });
  }, [workspaceId]);

  // Define the columns and their order
  const columns = ['REF', 'DATE', 'TX YEAR', 'V. DATE', 'Bank Description', 'CUR', 'AMOUNT', 'BRANCH COA', 'LEAF A', 'LEAF B'];

  // Define numeric fields for formatting
  const numericFields = ['AMOUNT'];
  
  // Define description fields for formatting
  const descriptionColumns = ['Bank Description'];

  // Function to format numeric values
  const formatNumeric = (value) => {
    return value ? Number(value).toLocaleString() : '-';
  };

  return (
    <div>
      <h2>Expenses</h2>
      <table>
        <thead>
          <tr>
            {columns.map((col) => (
              <th key={col}>{col}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {expenses.map((expense, index) => (
            <tr key={index}>
              {columns.map((col) => (
                <td key={col}>
                  {numericFields.includes(col)
                    ? formatNumeric(expense[col])
                    : expense[col] || '-'}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Expenses;
