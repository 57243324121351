import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

interface Props {
  url: string;
  name: string;
}

const token = localStorage.getItem('token');

const PdfUploader: React.FC<Props> = ({ url, name }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const inputName = e.target.name;
      const pdfContainer = e.target.closest('.input-shape') as HTMLElement;
      const icon = pdfContainer.getElementsByClassName('check-circle')[0] as HTMLElement;
      console.clear();
      console.log(icon);
      icon.classList.add('text-success');
      icon.classList.remove('inactive');
    }
  };

  const uploadPdf = async (file: File, inputName: string) => {
    try {
      // const formData = new FormData();
      // formData.append(inputName, file);

      // const response = await axios.post(url, formData, {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //     'Content-Type': 'multipart/form-data',
      //   },
      // });

      // console.log('File uploaded successfully:', response.data);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div className='shape'>
      <div
        style={{
          display: 'block',
          cursor: 'pointer',
          float: 'right',
          paddingRight: '1rem',
          paddingTop: '3px',
        }}
        onClick={handleIconClick}
      >
        <FontAwesomeIcon icon={faFilePdf} size="2x" color="#e74c3c" className='shape-icon' />
      </div>
      <input
        type="file"
        className='input-file-upload'
        ref={fileInputRef}
        style={{ display: 'none' }}
        accept="application/pdf"
        onChange={handleFileChange}
        name={name}
      />
    </div>
  );
};

export default PdfUploader;
