import React, { useEffect, useState } from 'react'
import './../../css/LandingSection.css'
import Gray from './../../assets/Gray.svg'
import { Link } from 'react-router-dom'
import axios from 'axios';
import Logout from '../auth/Logout';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from './../../reducers/reducers';
import { useLocalStorage } from '../../utilities/costumedHooks';
import { setSelectedWorkspace, setWorkspaces } from '../../features/WorkspaceSlice';
import UserCircle from '../auth/UserCircle';
import Alert from '../shapes/Alert';
// const token = localStorage.getItem('token');
// const user = localStorage.getItem('user');
interface Workspace {
  id: number;
  name: string;
  description: string;
  snippet: string;
  image: string;
}

interface UserOfWorkspace {
  id: number;
  name: string;
  workspace_id: number;
  user_id: number;
  role_id: number;
}

const LandingSection = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.userState);
  const [userShortName, setUserShortName] = useState('');
  const [userofWorkspaces, setUserOfWorkspaces] = useState<UserOfWorkspace[]>();
  const workspaces = useSelector((state: RootState) => state.workspaceState);
  const [showAlert, setShowAlert] = useState(false);
  const [alertBody, setAlertBody] = useState('test');
  const [alertStatus, setAlertStatus] = useState('test');

  useEffect(() => {
    const isAccountConfirmed = localStorage.getItem('isAccountConfirmed');
    if (isAccountConfirmed) {
      setShowAlert(true);
      setAlertBody('Your account is confirmed. Happy Sejel Experience');
      setAlertStatus('success');
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
      localStorage.removeItem('isAccountConfirmed'); // Clear the flag after showing the popup
    }
  }, []);

  const handleShowAccountPopup = () => {
    const accountPopup = document.getElementById('account-popup') as HTMLElement;
    if (accountPopup) {
      if (accountPopup.classList.contains('overnight')) {
        accountPopup.classList.remove('overnight');
      } else {
        accountPopup.classList.add('overnight');
      }
    }
  }

  const handleWorkspaceNavigation = (e: React.MouseEvent<HTMLImageElement>) => {
    const workspaceId = e.currentTarget.getAttribute('data-id');
    workspaces.workspaces.filter((workspace) => {
      if (workspace.id === parseInt(workspaceId as string)) {
        dispatch(setSelectedWorkspace(workspace));
        console.log(workspace);
      }
    })
  }

  useEffect(() => {
    const storedToken = localStorage.getItem('token') ?? '';
    setUserShortName(user ? user.name.substring(0, 2).toUpperCase() : '');
    if (storedToken && user) {
      getWorkspacesOfUser(storedToken, user.id);
    }
  }, []);

  const getWorkspacesOfUser = async (token: string, userId: number) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/get-workspaces-of-user/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      dispatch(setWorkspaces(response.data.workspaces));
      dispatch(setSelectedWorkspace(response.data.workspaces[0]))
      setSelectedWorkspace(localStorage.getItem('selectedWorkspace') ? JSON.parse(localStorage.getItem('selectedWorkspace') as string) : response.data.workspaces[0]);
      setUserOfWorkspaces(response.data.usersOfWorkspaces);
      // setSelectedWorkspace(response.data.workspaces[0]);
      localStorage.setItem('selectedWorkspace', JSON.stringify(response.data.workspaces[0]));
      console.log('response.data');
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching workspaces:', error);
    }
  };

  // useEffect(() => {
  //   console.clear();
  //   console.log('userofWorkspaces');
  //   console.log(userofWorkspaces ? userofWorkspaces[0].name : '');
  // }, [userofWorkspaces]);

  return (
    <div id="companies-side" className='main-side flex-10'>
      {showAlert ? <Alert body={alertBody} status={alertStatus} /> : ""}
      <div>
        <div id="user-section" className="flex-container flex-end">
          <UserCircle />
        </div>

        <div id="companies">

          {
            workspaces.workspaces.length
              ?
              <div className="add_workspace companies-row bg-white rounded-radius" style={{ width: '98%', 'height': '2rem' }}>
                <div className="bg-white w-100 flex-container flex-center rounded-radius">
                  <Link className='align-center w-100 color-black' to="/workspace-info">
                    ADD A WORKSPACE
                  </Link>
                </div>
              </div>
              :
              ''
          }
          {
            workspaces.workspaces.length ? workspaces.workspaces.map((workspace, index) => (
              <div key={index} className='collaborator-row' data-id={workspace.id}>
                <div className="collaborator-container">
                </div>
                <div className="collaborator_users_section flex-container flex-start mb-1 m-l-25-neg">

                  <div className='icon-container'>
                    <div className="collaborator-circle">
                      <p className={`short-name ${index == 0 ? 'white_font' : ''}`}>{workspace.id == 1 && userofWorkspaces ? userofWorkspaces[0].name.substring(0, 2).toUpperCase() : ''}</p>
                      {
                        workspace.id == 1 && userofWorkspaces ?
                          <span className="hover-label">{userofWorkspaces[0].name}</span>
                          : <></>
                      }
                    </div>
                  </div>

                  <div className='icon-container'>
                    <div className="collaborator-circle">
                      <p className="short-name">{workspace.id == 1 && userofWorkspaces ? userofWorkspaces[1].name.substring(0, 2).toUpperCase() : ''}</p>
                      {
                        workspace.id == 1 && userofWorkspaces ?
                          <span className="hover-label">{userofWorkspaces[1].name}</span>
                          : <></>
                      }
                    </div>
                  </div>

                  {
                    user.role_id > 2 ?
                      <div className="collaborator-circle">
                        <p className="short-name"></p>
                      </div>
                      :
                      <Link to={`/collaborator/${workspace.id}`}>
                        <div className="collaborator-circle hover-element">
                          <p className="short-name">+</p>
                        </div>
                      </Link>
                  }
                </div>
                <div className="companies-row">
                  <Link to={`/dashboard/${workspace.uniCode}`}>
                    <div className="left-column flex-column flex-center flex-container">

                      <img src={workspace && workspace.image ? process.env.REACT_APP_Laravel_API_URL_STRUCTURE + 'storage/' + workspace.image : Gray} onClick={handleWorkspaceNavigation} data-id={workspace.id} alt="company-image" className='company-image rounded-radius' />

                      <div className="company-lower-part bg-dark-gray w-100 rounded-radius mt-1">
                        <p className="company-name align-center bold-text white_font">{workspace ? workspace.name : 'loading'}</p>
                      </div>
                    </div>
                  </Link>
                  <div className="right-column flex-column">
                    <div className="company-description rounded-radius pd-1">
                      <p>
                        {workspace ? workspace.description : 'loading'}
                      </p>
                    </div>
                    <div className="company-short-description bg-dark-gray rounded-radius mt-1">
                      <div className="company-lower-part bg-dark-gray w-100 rounded-radius mt-1">
                        <p className='white_font'>
                          {workspace ? workspace.snippet : 'loading'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )) : null
          }
        </div>
      </div>
    </div >
  )
}

export default LandingSection