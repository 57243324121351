// reducers.ts
import { combineReducers } from 'redux';
import UserSlice from '../features/users/UserSlice';
import WorkspaceSlice from '../features/WorkspaceSlice';
import { LOGOUT } from '../actions/types'; // Import your LOGOUT action type

const appReducer = combineReducers({
  userState: UserSlice,
  workspaceState: WorkspaceSlice,
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: any) => {
  if (action.type === LOGOUT) {
    // Set userState to undefined
    return appReducer({ ...state, userState: undefined, workspaceState: undefined }, action);
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
