import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { AirtableRecord } from '../../types/airtable';
import '../../css/PLTable.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const PPETable: React.FC = () => {
  const selectedWorkspace = useSelector((state: RootState) => state.workspaceState.selectedWorkspace);
  const [data, setData] = useState<AirtableRecord[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/sejel-airtable-data/ASSETS DB`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      });
      setData(response.data.records);
      setLoading(false);
    } catch (err) {
      setError((err as Error).message);
      setLoading(false);
    }
  };

  const processPPEData = (records: AirtableRecord[]) => {
    const ppeData: { [year: number]: any } = {};
    const startingYear = 2020;
    const depreciationRate = 0.2;

    // Initialize years for Electronics and Furniture
    for (let year = startingYear; year < startingYear + 10; year++) {
      ppeData[year] = {
        electronics: {
          broughtForward: 0,
          additions: 0,
          depreciation: 0,
          endingBalance: 0,
        },
        furniture: {
          broughtForward: 0,
          additions: 0,
          depreciation: 0,
          endingBalance: 0,
        },
        total: {
          broughtForward: 0,
          additions: 0,
          depreciation: 0,
          endingBalance: 0,
        }
      };
    }

    records.forEach(record => {
      const date = new Date(record.fields['DATE'] as string);
      const year = date.getFullYear();
      const amount = parseFloat(record.fields['AMOUNT'] as string);
      const category = record.fields['CATEGORY'] || 'electronics'; // Assume category is provided

      if (year >= startingYear && year < startingYear + 10) {
        ppeData[year][category].additions += amount; // Track the additions
      }

      // Depreciate the asset over 5 years starting from the year after the purchase year
      for (let i = 1; i <= 5; i++) {
        const currentYear = year + i;
        if (currentYear >= startingYear && currentYear < startingYear + 10) {
          const depreciationAmount = amount * depreciationRate;
          ppeData[currentYear][category].depreciation += depreciationAmount;
        }
      }
    });

    // Calculate broughtForward, endingBalance, and totals
    for (let year = startingYear; year < startingYear + 10; year++) {
      if (year > startingYear) {
        ppeData[year].electronics.broughtForward = ppeData[year - 1].electronics.endingBalance;
        ppeData[year].furniture.broughtForward = ppeData[year - 1].furniture.endingBalance;
      }
      ppeData[year].electronics.endingBalance = ppeData[year].electronics.broughtForward + ppeData[year].electronics.additions - ppeData[year].electronics.depreciation;
      ppeData[year].furniture.endingBalance = ppeData[year].furniture.broughtForward + ppeData[year].furniture.additions - ppeData[year].furniture.depreciation;

      // Calculate totals
      ppeData[year].total.broughtForward = ppeData[year].electronics.broughtForward + ppeData[year].furniture.broughtForward;
      ppeData[year].total.additions = ppeData[year].electronics.additions + ppeData[year].furniture.additions;
      ppeData[year].total.depreciation = ppeData[year].electronics.depreciation + ppeData[year].furniture.depreciation;
      ppeData[year].total.endingBalance = ppeData[year].electronics.endingBalance + ppeData[year].furniture.endingBalance;
    }

    return ppeData;
  };

  useEffect(() => {
    fetchData();
  }, []);

  const ppeData = processPPEData(data);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const formatNumber = (value: any) => {
    return new Intl.NumberFormat('en-US').format(value.toFixed(2));
  };

  return (
    <div className="table-container">
      <h1>PPE - {selectedWorkspace.name}, IN SAR</h1>
      <table>
        <thead>
          <tr>
            <th></th>
            {[...Array(10)].map((_, index) => (
              <th key={index}>{2020 + index}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={11}><b>FURNITURE</b></td>
          </tr>
          <tr>
            <td>Balance Brought Forward</td>
            {[...Array(10)].map((_, index) => (
              <td key={index}>{formatNumber(ppeData[2020 + index].furniture.broughtForward)}</td>
            ))}
          </tr>
          <tr>
            <td>Additions</td>
            {[...Array(10)].map((_, index) => (
              <td key={index}>{formatNumber(ppeData[2020 + index].furniture.additions)}</td>
            ))}
          </tr>
          <tr>
            <td>Depreciation</td>
            {[...Array(10)].map((_, index) => (
              <td key={index}>{formatNumber(ppeData[2020 + index].furniture.depreciation)}</td>
            ))}
          </tr>
          <tr>
            <td>Ending Balance</td>
            {[...Array(10)].map((_, index) => (
              <td key={index}>{formatNumber(ppeData[2020 + index].furniture.endingBalance)}</td>
            ))}
          </tr>
          <tr><td colSpan={11}>&nbsp;</td></tr>
          <tr>
            <td colSpan={11}><b>ELECTRONICS</b></td>
          </tr>
          <tr>
            <td>Balance Brought Forward</td>
            {[...Array(10)].map((_, index) => (
              <td key={index}>{formatNumber(ppeData[2020 + index].electronics.broughtForward)}</td>
            ))}
          </tr>
          <tr>
            <td>Additions</td>
            {[...Array(10)].map((_, index) => (
              <td key={index}>{formatNumber(ppeData[2020 + index].electronics.additions)}</td>
            ))}
          </tr>
          <tr>
            <td>Depreciation</td>
            {[...Array(10)].map((_, index) => (
              <td key={index}>{formatNumber(ppeData[2020 + index].electronics.depreciation)}</td>
            ))}
          </tr>
          <tr>
            <td>Ending Balance</td>
            {[...Array(10)].map((_, index) => (
              <td key={index}>{formatNumber(ppeData[2020 + index].electronics.endingBalance)}</td>
            ))}
          </tr>
          <tr><td colSpan={11}>&nbsp;</td></tr>
          <tr>
            <td colSpan={11}><b>TOTAL</b></td>
          </tr>
          <tr>
            <td>Balance Brought Forward</td>
            {[...Array(10)].map((_, index) => (
              <td key={index}>{formatNumber(ppeData[2020 + index].total.broughtForward)}</td>
            ))}
          </tr>
          <tr>
            <td>Additions</td>
            {[...Array(10)].map((_, index) => (
              <td key={index}>{formatNumber(ppeData[2020 + index].total.additions)}</td>
            ))}
          </tr>
          <tr>
            <td>Depreciation</td>
            {[...Array(10)].map((_, index) => (
              <td key={index}>{formatNumber(ppeData[2020 + index].total.depreciation)}</td>
            ))}
          </tr>
          <tr>
            <td>Ending Balance</td>
            {[...Array(10)].map((_, index) => (
              <td key={index}>{formatNumber(ppeData[2020 + index].total.endingBalance)}</td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PPETable;
