// import React, { useState } from 'react';
// import { Link, useParams } from 'react-router-dom';
// import Dashboard from '../../assets/Dashboard.svg';
// import BankBuilding from '../../assets/BankBuilding.svg';
// import ComboChart from '../../assets/ComboChart.svg';
// import Menu from '../../assets/Menu.svg';
// import Airtable from '../../assets/Airtable.svg';
// import AI from '../../assets/AI.svg';
// // import FinancialIcon from '../../assets/FinancialIcon.svg';
// import FinancialReportPopup from '../FinancialReports/FinancialReportPopup';
// import Logout from '../auth/Logout';
// import '../../css/RightNavbar.css';

import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Dashboard from '../../assets/Dashboard.svg';
import BankBuilding from '../../assets/BankBuilding.svg';
import ComboChart from '../../assets/ComboChart.svg';
import Menu from '../../assets/Menu.svg';
import Airtable from '../../assets/Airtable.svg';
import AI from '../../assets/AI.svg';
// import FinancialIcon from '../../assets/FinancialIcon.svg';
import FinancialReportPopup from '../FinancialReports/FinancialReportPopup';
import Logout from '../auth/Logout';
import '../../css/RightNavbar.css';

const RightNavbar = () => {
  const { id } = useParams();
  const [showPopup, setShowPopup] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleMouseEnter = () => {
    setExpanded(true);
  };

  const handleMouseLeave = () => {
    setExpanded(false);
  };

  const handleFinancialReportClick = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <div
        id="right-navbar"
        className={`vertical-flex-container ${expanded ? 'expanded' : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <img
            src={ComboChart}
            alt="Financial Report"
            className="nav-item"
            onClick={handleFinancialReportClick}
          />
      </div>
      <div id="right-trigger" onMouseEnter={handleMouseEnter}></div>
      {showPopup && <FinancialReportPopup onClose={closePopup} />}
    </>
  );
};

export default RightNavbar;
