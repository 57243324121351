import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Revenues({ workspaceId }) {
  const [revenues, setRevenues] = useState([]);
  
  useEffect(() => {
    // Fetch the revenues for the given workspace using Axios
    const token = localStorage.getItem('token');
    // `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/workspaces/${workspaceId}/revenues`
    axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/workspaces/${workspaceId}/revenues`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      })
      .then((response) => {
        setRevenues(response.data);
      })
      .catch((error) => {
        console.error('Error fetching revenues:', error);
      });
  }, [workspaceId]);

  // Define the columns and their order
  const columns = [
    'Trip/Contact Name',
    'REF-UNIQUE',
    'INVOICE DATE',
    'Y- VALUE',
    'VALUE DATE',
    'VERTICALS',
    'CUR',
    'TOTAL',
    'VAT',
    'TTA',
    'INCOMING',
    'RECEIVABLE',
    'COLL. SOURCE',
    'COLLECTION STATUS',
  ];

  // Define numeric fields for formatting
  const numericFields = ['TOTAL', 'VAT', 'TTA', 'INCOMING', 'RECEIVABLE'];

  // Function to format numeric values
  const formatNumeric = (value) => {
    return value ? Number(value).toLocaleString() : '-';
  };

  return (
    <div>
      <h2>Revenues</h2>
      <table>
        <thead>
          <tr>
            {columns.map((col) => (
              <th key={col}>{col}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {revenues.map((revenue, index) => (
            <tr key={index}>
              {columns.map((col) => (
                <td key={col}>
                  {numericFields.includes(col)
                    ? formatNumeric(revenue[col])
                    : revenue[col] || '-'}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Revenues;
