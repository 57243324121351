import React, { useEffect, useRef, useState } from 'react';
import '../../css/ImageSelector.css';
import Gray from '../../assets/Gray.svg';
interface ImageSelectorProps {
  initialImageUrl: string;
  onImageChange: (newImageUrl: string) => void;
  fileInputRef: React.RefObject<HTMLInputElement>;
}

const ImageSelector: React.FC<ImageSelectorProps> = ({ initialImageUrl, onImageChange, fileInputRef }) => {
  const [imageUrl, setImageUrl] = useState(initialImageUrl);
  useEffect(() => {
    setImageUrl(initialImageUrl);
  }, [initialImageUrl]);
  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          const image = new Image();
          image.src = reader.result as string;

          const newImageUrl = reader.result as string;
          setImageUrl(newImageUrl);
          onImageChange(newImageUrl);
          // image.onload = () => {
          //   if (image.width === image.height) {
          //   } else {
          //     alert('Please upload a square image.');
          //   }
          // };
        }
      };

      reader.readAsDataURL(file);
    }
  };


  return (
    <div className='image-container m-t-1'>
      <img src={imageUrl} alt="Selected" className='image-selector rounded-radius' onClick={handleImageClick} />
      <input
        type="file"
        ref={fileInputRef}
        className='file-input'
        onChange={handleFileChange}
        accept="image/*"
      />
    </div>
  );
};

export default ImageSelector;