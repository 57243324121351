import React, { useEffect, useRef, useState } from 'react'
import '../css/workspaceInfo.css'
import axios from 'axios'
import Alert from './shapes/Alert'
import { useNavigate, useParams, useLocation, useSearchParams } from 'react-router-dom';
import UserCircle from './auth/UserCircle'

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const query = useQuery();
  const [newPassword, setNewPassword] = useState('');
  const token = searchParams.get('token');
  const userId = searchParams.get('id');
  const [requiredInput, setRequiredInput] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [alertBody, setAlertBody] = useState('');
  const [alertStatus, setAlertStatus] = useState('');
  const [tempToken, setTempToken] = useState<string>();
  const [showAlert, setShowAlert] = useState(false);
  const inputId = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = document.querySelector('input[name="password"]') as HTMLInputElement;
    const confirmPassword = document.querySelector('input[name="confirmPassword"]') as HTMLInputElement;
    const submitButton = document.querySelector('input[type="submit"]') as HTMLInputElement;
    if ((password.value && confirmPassword.value) && (password.value === confirmPassword.value)) {
      console.clear();
      console.log(password.value);
      setNewPassword(password.value);
      submitButton.classList.remove('inactive');
    }
  };

  const handleResetPassword = async (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const passwordInput = document.querySelector('input[name="password"]') as HTMLInputElement;
    const confirmPasswordInput = document.querySelector('input[name="confirmPassword"]') as HTMLInputElement;
    try {
      if (passwordInput.value !== confirmPasswordInput.value) {
        setAlertBody('Passwords do not match');
        setAlertStatus('error');
        setShowAlert(true);
        return;
      }
      // Make an API request to reset the password
      const url = `${process.env.REACT_APP_API_URL}/password/reset`;
      await axios.post(url, {
        userId,
        newPassword,
        newPassword_confirmation: confirmPasswordInput.value,
      });
      // Redirect or show success message
      navigate('/login');
    } catch (error) {
      console.error(error);
      // Handle error, show error message
    } finally {
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
    }
  };

  useEffect(() => {
    const token = query.get('tempToken');
    if (token) {
      setTempToken(token);
      setRequiredInput(true);
    }
  }, [query]);

  return (
    <div className="main-container flex-container">
      <div>
        {showAlert ? <Alert body={alertBody} status={alertStatus} /> : ""}
      </div>
      <div id="workspace-info-side" className='main-side flex-10'>
        <div id="user-section" className="flex-container flex-space-between">
          <div id="title">
            <h3 className='bold-text'>Reset password</h3>
            <p>reset you password</p>
          </div>
          <UserCircle />
        </div>
        <div className="form-box bg-white rounded-radius w-50 m-t-5 pd-1">
          <form id='workspace-form' method="post" onSubmit={handleResetPassword}>
            <input type="hidden" name="id" id="id" placeholder='id' value={userId ? userId : ""} ref={inputId} />
            <input type="hidden" name="tempToken" id="tempToken" placeholder='tempToken' value={tempToken} />
            {/* <input type="email" value={email} name='' placeholder='email' className={`inactive-input workspace-input rounded-radius ${tempToken ? 'overnight' : ''}`} readOnly required={requiredInput} /> */}
            <input type="password" onChange={handleChange} name='password' placeholder='password' className='workspace-input rounded-radius' required={requiredInput} />
            <input type="password" onChange={handleChange} name='confirmPassword' placeholder='confirm password' className='workspace-input rounded-radius' required={requiredInput} />
            <input type="submit" value="Save" className='submit-button rounded-radius m-t-1' />
            {/* <p className="bold-text" style={{ 'color': '#94ddf3' }}>loading...</p> */}
            {
              loading ? <p className="bold-text" style={
                {
                  'color': '#94ddf3',
                  'display': 'inline-block',
                  'marginLeft': '1rem',
                  'fontSize': '2rem',
                  'top': '4px',
                  'position': 'relative',
                }}>
                Loading...
              </p> : ""
            }
          </form>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword