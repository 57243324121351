import React from 'react'
import Logout from './Logout';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';


const UserCircle = () => {
  const user = useSelector((state: RootState) => state.userState)
  const handleShowAccountPopup = () => {
    const accountPopup = document.getElementById('account-popup') as HTMLElement;
    if (accountPopup) {
      if (accountPopup.classList.contains('overnight')) {
        accountPopup.classList.remove('overnight');
      } else {
        accountPopup.classList.add('overnight');
      }
    }
  }
  return (
    <>
      <div className="user-circle pointer" onClick={handleShowAccountPopup}>
        <p className="short-name">{user.name.substring(0, 2).toUpperCase()}</p>
      </div>
      <div id='account-popup' className="rounded-radius overnight">
        <ul>
          <li className='rounded-radius'>
            <Link to={`/user/${user.id}`} className={`account-drop-down-item`} >User Profile</Link>
          </li>
          <li className='rounded-radius'>
            <Logout logoutStyle='from-account' />
          </li>
        </ul>
      </div>
    </>
  )
}

export default UserCircle