import React, { useState } from 'react';
import axios from 'axios';
import './SyncButton.css'; // Import the external CSS file

interface SyncButtonProps {
  workspaceId: number;
}

const SyncButton: React.FC<SyncButtonProps> = ({ workspaceId }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  // Function to trigger the sync
  const handleSync = async () => {
    setLoading(true);
    setMessage(null);

    try {
      const token = localStorage.getItem('token');
      // Send a POST request to trigger the sync
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/sync-workspace/${workspaceId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      });

      // Display success message
      setMessage(response.data.message);
    } catch (error) {
      // Handle errors and display an error message
      console.error('Error syncing:', error);
      setMessage('Failed to sync');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <button onClick={handleSync} className="button" disabled={loading}>
        {loading ? 'Syncing...' : 'Sync'}
      </button>

      {message && <p className="message">{message}</p>}
    </>
  );
};

export default SyncButton;
