// ModalComponent
import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Ensure this is set for accessibility

interface Props {
  logoutStyle?: string;
  content: string;
  classProp: string;
  message: string;
  actionName: string;
  label: string;
  action: (e: React.MouseEvent<HTMLElement>, action: string, closeModal: () => void) => Promise<void>;
}

const ModalComponent: React.FC<Props> = ({ logoutStyle, content, classProp, message, action, actionName, label }) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  return (
    <div>
      <div className="icon-container">
        <img src={content} onClick={openModal} className={classProp} />
        <span className="hover-label">{label}</span>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Confirmation Modal"
        className="logout-modal"
        overlayClassName="overlay"
      >
        <h2>Confirm</h2>
        <p>{message}</p>
        <div className="modal-buttons">
          <button onClick={(e) => action(e, actionName, closeModal)} className="confirm-button">Yes</button>
          <button onClick={closeModal} className="cancel-button">No</button>
        </div>
      </Modal>
    </div>
  );
};

export default ModalComponent;
