// src/components/Register.tsx
import React, { useState } from 'react';
import { register } from '../../services/api';
import '../../css/Register.css';
import SEJELLogo from '../../assets/png_trns_logo_white 1.svg';
import GoogleIcon from '../../assets/GoogleIcon.png';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from '../../features/users/UserSlice';
import Alert from './../shapes/Alert'

const Register: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [requiredInput, setRequiredInput] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertBody, setAlertBody] = useState('test');
  const [alertStatus, setAlertStatus] = useState('test');
  const [loading, setLoading] = useState(false);

  function validatePassword(password: string): boolean {
    // Define the regex pattern
    const passwordPattern = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,}$/;

    // Check if the password matches the pattern
    if (!passwordPattern.test(password)) {
      return false;
    }
    // Return null if the password is true
    return true;
  }


  const handleSubmit = async (e: React.FormEvent) => {
    console.clear();
    console.log('submitting');
    e.preventDefault();
    const login = document.getElementById('login-button') as HTMLButtonElement;
    try {
      setLoading(true);
      login?.setAttribute('disabled', 'true');
      if (!validatePassword(password)) {
        setShowAlert(true)
        setAlertBody('Password must be at least 8 characters long, include at least one number, and one special character.');
        setAlertStatus('error');
      } else {
        const response = await register(name, email, password);
        const userData = response.data.user;
        dispatch(setUser(userData));
        localStorage.setItem('token', response.data.token);
        setShowAlert(true)
        setAlertBody('Verify your email to continue');
        setAlertStatus('success');
      }
    } catch (error) {
      console.error(error);
      setShowAlert(true)
      setAlertBody('Registration Failed!');
      setAlertStatus('error');
    } finally {
      login?.removeAttribute('disabled');
      setLoading(false);
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
    }
  };

  return (
    <div className='login-page'>
      <div className="flex-container">
        <div className="flex-2-2 left-side">
          <div className="register-container login-container flex-container flex-center">
            <div className="login-box">
              <h2>Sign up for free and building your financials in minute</h2>
              <form onSubmit={handleSubmit}>
                <div className="user-box">
                  <input type="text"
                    name='name' placeholder='Full Name'
                    className='workspace-input rounded-radius'
                    autoComplete='off' required={requiredInput}
                    onChange={(e) => setName(e.target.value)} />
                </div>
                <div className="user-box">
                  <input type="email" name='email' placeholder='Email' className='workspace-input rounded-radius' autoComplete='off' required={requiredInput} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="user-box">
                  <input type="password" name='password' placeholder='Password' className='workspace-input rounded-radius' autoComplete='off' required={requiredInput} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className="password-info flex-container flex-center w-100">
                  <span>
                    Use 8+ characters, including numbers and special characters.
                  </span>
                </div>
                <div className="accept-terms flex-container flex-start m-t-2">
                  <input type="checkbox" id="accept-terms" />
                  <label htmlFor="accept-terms" className='m-l-1'>I accept the <span className='term-color'>Terms and Conditions</span></label>
                </div>

                <button type="submit" id='login-button' className="login-button">
                  Sign Up for Free
                </button>
                {error && <span className="error">{error}</span>}
                <div className="flex-container flex-center m-t-1 m-b-1">
                  Or
                </div>
                <div className="google-button flex-container flex-center">
                  <div className="icon">
                    <img src={GoogleIcon} alt="google" className='image-w-3' />
                  </div>
                  <div className="text">Continue with Google</div>
                </div>
              </form>
              <div className="sign-in-section flex-container flex-center w-100 m-t-2">
                <p>
                  Already have an account? <Link to='/login' className='term-color'>Sign In</Link>
                </p>
              </div>
              <div className="flex-container flex-center">
                {
                  loading === true ? <p className="bold-text" style={
                    {
                      'color': '#94ddf3',
                      'display': 'inline-block',
                      'marginLeft': '1rem',
                      'fontSize': '2rem',
                    }}>
                    Loading...
                  </p> : ""
                }
              </div>
              {/* <div className="register-section">
                <p>New to Sejel? <Link to='/register'>Create an Account</Link></p>
              </div> */}
            </div>
          </div>
        </div>
        <div className="flex-2-2">
          <img src={SEJELLogo} alt="logo" />
        </div>
      </div>
      {showAlert ? <Alert body={alertBody} status={alertStatus} /> : ""}
    </div >
  );
  // return (

  //   // <UpdateUser registerProp={true}/>
  // );
};

export default Register;
