import axios from 'axios';
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ConfirmRegistration = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const tempToken = query.get('tempToken');
  useEffect(() => {
    if (tempToken) {
      const data = new FormData();
      data.append('tempToken', tempToken)
      const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/confirm-registration`;
      const response = axios.post(url, data)

      console.clear();
      console.log(response);

      // Set flag in localStorage
      localStorage.setItem('isAccountConfirmed', 'true');
      navigate('/')
    }
  }, [tempToken]);
  return (
    <div>confirming your email please be patient...</div>
  )
}

export default ConfirmRegistration