"use strict";
exports.__esModule = true;
exports.fetchRevenues = exports.getUser = exports.logout = exports.login = exports.register = void 0;
// src/services/api.ts
var axios_1 = require("axios");
var API_URL = "".concat(process.env.REACT_APP_API_URL);
var register = function (name, email, password) {
    return axios_1["default"].post("".concat(API_URL, "/register"), { name: name, email: email, password: password });
};
exports.register = register;
var login = function (email, password) {
    return axios_1["default"].post("".concat(API_URL, "/login"), { email: email, password: password });
};
exports.login = login;
var logout = function () {
    return axios_1["default"].post("".concat(API_URL, "/logout"), {}, {
        headers: { Authorization: "Bearer ".concat(localStorage.getItem('token')) }
    });
};
exports.logout = logout;
var getUser = function (token) {
    return axios_1["default"].get("".concat(API_URL, "/user"), { headers: { Authorization: "Bearer ".concat(token) } });
};
exports.getUser = getUser;
var fetchRevenues = function (workspaceId, token) {
    return axios_1["default"].get("".concat(API_URL, "/workspaces/").concat(workspaceId, "/revenues"), { headers: { Authorization: "Bearer ".concat(token) } });
};
exports.fetchRevenues = fetchRevenues;
