import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import '../../css/airtable-data.css';
import DepreciationPopup from './DepreciationPopup';
import { AirtableRecord } from '../../types/airtable';
import black from '../../assets/black.svg';

window.Pusher = Pusher;

const echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.REACT_APP_PUSHER_KEY!,
  cluster: process.env.REACT_APP_PUSHER_CLUSTER!,
  forceTLS: true,
  encrypted: true,
});

interface AirtableDataProps {
  tableName: string;
}

const columnOrders: { [key: string]: string[] } = {
  'BANK DB': ['REF', 'DATE', 'Bank Description', 'CUR', 'AMOUNT', 'BALANCE'],
  'REV BASE 2': ['Trip/Contact Name', 'REF-UNIQUE', 'INVOICE DATE', 'Y- VALUE', 'VALUE DATE', 'VERTICALS', 'CUR', 'TOTAL', 'VAT', 'TTA', 'INCOMING', 'RECEIVABLE', 'COLL. SOURCE', 'COLLECTION STATUS'],
  'EXP DB': ['REF', 'DATE', 'TX YEAR', 'V. DATE', 'Bank Description', 'CUR', 'AMOUNT', 'BRANCH COA', 'LEAF A', 'LEAF B', 'BRANCH COA'],
  'ASSETS DB': ['REF', 'Bank Description', 'DATE', 'AMOUNT', 'BRANCH COA', 'DEPRECIATION PERIOD', 'RESIDUAL VALUE'], // Add your specific columns here
};

const numericColumns: { [key: string]: string[] } = {
  'BANK DB': ['AMOUNT', 'BALANCE'],
  'REV BASE 2': ['TOTAL', 'VAT', 'TTA', 'INCOMING', 'RECEIVABLE'],
  'EXP DB': ['AMOUNT'],
  'ASSETS DB': ['AMOUNT', 'RESIDUAL VALUE'], // Add your specific numeric columns here
};

const descriptionColumns: { [key: string]: string[] } = {
  'BANK DB': ['Bank Description'],
  'REV BASE 2': [],
  'EXP DB': ['Bank Description'],
  'ASSETS DB': ['Bank Description'], // Add your specific numeric columns here
};

const AirtableData: React.FC<AirtableDataProps> = ({ tableName }) => {
  const [records, setRecords] = useState<AirtableRecord[]>([]);
  const [headers, setHeaders] = useState<string[]>([]);
  const initialFetch = useRef(true);
  const [data, setData] = useState<AirtableRecord[]>([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<AirtableRecord | null>(null);
  const dataRef = useRef(data); // Ref to store the latest data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/sejel-airtable-data/${tableName}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      });
      const records = tableName === 'ASSETS DB' ? response.data.records : response.data.original.records;
      setData(records);
      setLoading(false);
      if (records.length > 0) {
        const columnOrder = getColumnOrder(records);
        setHeaders(columnOrder);
      }
    } catch (err) {
      setError(err as Error);
      setLoading(false);
    }
  };

  const getColumnOrder = (records: AirtableRecord[]): string[] => {
    const fixedOrder = columnOrders[tableName] || [];
    if (records.length > 0) {
      return fixedOrder;
    }
    return fixedOrder;
  };

  useEffect(() => {
    if (initialFetch.current) {
      initialFetch.current = false;
      fetchData();
    }

    const pusherKey = process.env.REACT_APP_PUSHER_KEY || '';
    const pusherCluster = process.env.REACT_APP_PUSHER_CLUSTER || '';

    if (!pusherKey || !pusherCluster) {
      console.error('Pusher key and cluster must be provided');
    } else {
      const pusher = new Pusher(pusherKey, {
        cluster: pusherCluster,
        forceTLS: true, // Use forceTLS instead of encrypted
      });

      pusher.connection.bind('connected', () => {
        console.log('Pusher connection established successfully!!!!!!');
      });

      const channel = pusher.subscribe('sejel-development');
      pusher.connection.bind('error', function (err: any) {
        if (err.error.data.code === 4004) {
          console.log('Over the connection limit!');
        } else {
          console.log('Connection error:', err);
        }
      });

      pusher.connection.bind('disconnected', function () {
        console.log('Disconnected from Pusher');
      });

      pusher.connection.bind('connected', function () {
        console.log('Connected to Pusher');
      });
      channel.bind('airtable-updated', (event: { data: AirtableRecord[] }) => {
        // loop over the previous data and update the record
        let updatedData = dataRef.current.map((record) => {
          const updatedRecord = event.data.find((updatedRecord) => updatedRecord.id === record.id);
          return updatedRecord || record;
        });

        if (event.data.length > 0) {
          setData(updatedData);
        }
      });

      return () => {
        echo.leaveChannel('sejel-development');
      };
    }
  }, []);

  useEffect(() => {
    dataRef.current = data; // Update the ref whenever data changes
    // console.log(headers);
    // console.log('uf - Data: ', data);
  }, [data]);

  const formatNumber = (value: any) => {
    return new Intl.NumberFormat('en-US').format(value);
  };

  const handleRowClick = (record: AirtableRecord) => {
    if(tableName != "ASSETS DB") return false;
    setSelectedRecord(record);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedRecord(null);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="table-container">
      <div className="flex-container flex-start">
        <h1>{tableName == "BANK DB" ? "INMA" : "Airtable Data"}</h1>
        {tableName == "BANK DB" ? <img src={black} alt="INMA" className='p-l-1' /> : ""}
      </div>
      <table>
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data.map((record) => (
              <tr key={record.id} data-id={record.id} onClick={() => handleRowClick(record)}>
                {headers.map((header) => (
                  // <td key={header} >
                  //   {numericColumns[tableName]?.includes(header)
                  //     ? formatNumber(record.fields[header])
                  //     : header == 'Bank Description' && record.fields[header].length > 60 ? record.fields[header].substring(0, 60) + "..." : record.fields[header]}
                  // </td>
                  <td key={header}>
                  {numericColumns[tableName]?.includes(header)
                    ? formatNumber(record.fields[header])
                    : descriptionColumns[tableName]?.includes(header) ? record.fields[header] && record.fields[header].length > 60 ? record.fields[header].substring(0, 60) + "..." : record.fields[header] : record.fields[header]}
                    
                </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={headers.length}>No data available</td>
            </tr>
          )}
        </tbody>
      </table>
      {showPopup && selectedRecord && (
        <DepreciationPopup record={selectedRecord} onClose={handleClosePopup} />
      )}
    </div>
  );
};

export default AirtableData;
