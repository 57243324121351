import React from 'react';
import '../../css/FinancialStatements.css'; // Ensure you create and style this CSS file
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const financialData = {
  growthInRevenue: {
    "2020": "0.00%",
    "2021": "11.27%",
    "2022": "128.50%",
    "2023": "77.76%",
    "2024": "-53.99%",
  },
  revenueAVI: {
    "2020": 0,
    "2021": 0,
    "2022": 11050544,
    "2023": 19643941,
    "2024": 9037709,
  },
  vat: {
    "2020": 0,
    "2021": 0,
    "2022": 1441375,
    "2023": 2562253,
    "2024": 1171396,
  },
  revenueAFV: {
    "2020": 4346322,
    "2021": 4836106,
    "2022": 9609167,
    "2023": 17081687,
    "2024": 7858878,
  },
  costOfRevenue: {
    "2020": -2648949,
    "2021": -2849249,
    "2022": -3756791,
    "2023": -11838887,
    "2024": -4995938,
  },
  grossProfit: {
    "2020": 1697373,
    "2021": 1986857,
    "2022": 5852376,
    "2023": 5242800,
    "2024": 2862940,
  },
  grossProfitMargin: {
    "2020": "39.05%",
    "2021": "41.08%",
    "2022": "52.96%",
    "2023": "26.69%",
    "2024": "31.68%",
  },
  personnelExpenses: {
    "2020": -837922,
    "2021": -1035173,
    "2022": -1571477,
    "2023": -2752900,
    "2024": -1407278,
  },
  generalAndAdminExpenses: {
    "2020": -565228,
    "2021": -230091,
    "2022": -634648,
    "2023": -1171553,
    "2024": -467361,
  },
  ebitda: {
    "2020": 294223,
    "2021": 721593,
    "2022": 5217728,
    "2023": 4071247,
    "2024": 2395579,
  },
  ebitdaMargin: {
    "2020": "6.77%",
    "2021": "14.92%",
    "2022": "47.22%",
    "2023": "20.73%",
    "2024": "26.51%",
  },
  depreciation: {
    "2020": -22321,
    "2021": -15766,
    "2022": -15933,
    "2023": -16453,
    "2024": -5229,
  },
  ebit: {
    "2020": 271902,
    "2021": 705827,
    "2022": 5201796,
    "2023": 4054794,
    "2024": 2390343,
  },
  ebitMargin: {
    "2020": "6.26%",
    "2021": "14.59%",
    "2022": "47.07%",
    "2023": "20.64%",
    "2024": "26.45%",
  },
  interest: {
    "2020": 0,
    "2021": 0,
    "2022": 0,
    "2023": 0,
    "2024": -35007,
  },
  ebt: {
    "2020": 271902,
    "2021": 705827,
    "2022": 5201796,
    "2023": 4054794,
    "2024": 2355343,
  },
  ebtMargin: {
    "2020": "6.26%",
    "2021": "14.59%",
    "2022": "47.07%",
    "2023": "20.64%",
    "2024": "26.06%",
  },
  zakat: {
    "2020": -10501,
    "2021": -30888,
    "2022": -156947,
    "2023": -277297,
    "2024": 0,
  },
  otherIncomeExpenses: {
    "2020": 0,
    "2021": 0,
    "2022": 10501,
    "2023": 30888,
    "2024": 0,
  },
  netProfit: {
    "2020": 261401,
    "2021": 674939,
    "2022": 5055350,
    "2023": 3808385,
    "2024": 2355343,
  },
  netProfitMargin: {
    "2020": "6.01%",
    "2021": "13.96%",
    "2022": "45.75%",
    "2023": "19.39%",
    "2024": "26.06%",
  },
};

const FinancialStatements: React.FC = () => {
  const selectedWorkspace = useSelector((state: RootState) => state.workspaceState.selectedWorkspace);
  const years = ["2020", "2021", "2022", "2023", "2024"];

  const formatNumber = (value: any) => {
    return new Intl.NumberFormat('en-US').format(value);
  };

  return (
    <div className="table-container">
      <h1>Financial Statements - {selectedWorkspace.name}, IN SAR</h1>
      <table className="financial-statements-table">
        <thead>
          <tr>
            <th>FINANCIAL STATEMENTS - {selectedWorkspace.name}, IN SAR</th>
            {years.map((year) => (
              <th key={year}>{year}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr><td colSpan={years.length + 1}><b>PROFIT / LOSS STATEMENT</b></td></tr>
          <tr>
            <td>GROWTH IN REVENUE</td>
            {years.map((year) => (
              <td key={year}>{financialData.growthInRevenue[year]}</td>
            ))}
          </tr>
          <tr>
            <td>REVENUE AVI</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.revenueAVI[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>VAT</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.vat[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>REVENUE AFV</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.revenueAFV[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>COST OF REVENUE</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.costOfRevenue[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>GROSS PROFIT (LOSS)</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.grossProfit[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>GROSS PROFIT (LOSS) MARGIN</td>
            {years.map((year) => (
              <td key={year}>{financialData.grossProfitMargin[year]}</td>
            ))}
          </tr>
          <tr><td colSpan={years.length + 1}>&nbsp;</td></tr>
          <tr>
            <td>PERSONNEL EXPENSES</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.personnelExpenses[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>GENERAL AND ADMINISTRATIVE EXPENSES</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.generalAndAdminExpenses[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>EBITDA</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.ebitda[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>EBITDA MARGIN</td>
            {years.map((year) => (
              <td key={year}>{financialData.ebitdaMargin[year]}</td>
            ))}
          </tr>
          <tr><td colSpan={years.length + 1}>&nbsp;</td></tr>
          <tr>
            <td>DEPRECIATION</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.depreciation[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>EBIT</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.ebit[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>EBIT MARGIN</td>
            {years.map((year) => (
              <td key={year}>{financialData.ebitMargin[year]}</td>
            ))}
          </tr>
          <tr><td colSpan={years.length + 1}>&nbsp;</td></tr>
          <tr>
            <td>INTEREST</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.interest[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>EBT</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.ebt[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>EBT MARGIN</td>
            {years.map((year) => (
              <td key={year}>{financialData.ebtMargin[year]}</td>
            ))}
          </tr>
          <tr><td colSpan={years.length + 1}>&nbsp;</td></tr>
          <tr>
            <td>ZAKAT</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.zakat[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>OTHER INCOME / EXPENSES</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.otherIncomeExpenses[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>NET PROFIT</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.netProfit[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>NET PROFIT MARGIN</td>
            {years.map((year) => (
              <td key={year}>{financialData.netProfitMargin[year]}</td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FinancialStatements;
