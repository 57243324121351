import { createSlice } from '@reduxjs/toolkit';

interface UserSlice {
  id: number;
  name: string;
  email: string;
  role_id: number;
}

const initialState: UserSlice = {
  id: 0,
  name: '',
  email: '',
  role_id: 0,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUser: (state, action) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.role_id = action.payload.role_id;
    },
    setUser: (state, action) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.role_id = action.payload.role_id;
      console.clear();
      console.log(action.payload.role_id)
    },
    loadUserFromStorage: (state) => {
      const user = JSON.parse(localStorage.getItem('user') || '{}');
      if (user.id) {
        state.id = user.id;
        state.name = user.name;
        state.email = user.email;
      }
    },
    // remove user from redux
    removeUserFromRedux: (state) => {
      return undefined;
    }
  },
});

export const { getUser, setUser, loadUserFromStorage, removeUserFromRedux } = userSlice.actions;
export default userSlice.reducer;
