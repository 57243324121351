import React, { useEffect, useState } from 'react'
import Users from './../../assets/Users.svg'
import WorkspaceInfo from './../../assets/WorkspaceInfo.svg'
import CreditCard from './../../assets/CreditCard.svg'
import Expand from './../../assets/Expand.svg'
import TTP from './../../assets/TTP.svg'
import Gray from './../../assets/Gray.svg'
import PinkLogo from './../../assets/PinkLogo.svg'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

interface Props {
  pages: { [key: string]: string[] };
  pageProp: string;
  hidePopup: boolean;
  hidePanelContents: boolean;
}

const LandingMenu: React.FC<Props> = ({ pages, pageProp, hidePopup, hidePanelContents }) => {
  const selectedWorkspace = useSelector((state: RootState) => state.workspaceState.selectedWorkspace);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<number | null>(null)

  useEffect(() => {
    if (selectedWorkspace) setSelectedWorkspaceId(selectedWorkspace.id);
  }, [selectedWorkspace]);


  const accordionClick = (e: React.MouseEvent<HTMLElement>) => {
    const workspaceInfo = document.getElementById('workspace-info') as HTMLElement;
    if (workspaceInfo) {
      if (workspaceInfo.style.maxHeight) {
        workspaceInfo.style.maxHeight = "";
        setTimeout(() => {
          workspaceInfo.style.display = "none";
        }, 380);
      } else {
        workspaceInfo.style.display = "block";
        workspaceInfo.style.maxHeight = workspaceInfo.scrollHeight + "px";
      }
    }
  }
  return (
    <div id="menu-side" className='flex-2-4 p-l-1'>
      <div>
        <Link to="/">
          <img id='landing-menu-logo' className='logo' src={PinkLogo} alt="" />
        </Link>
      </div>
      {hidePanelContents ? <></> :
        <div className="menu-main-content vertical-flex flex-space-between">
          <div id='option-container' className="options">
            {
              hidePanelContents ? ''
                :
                <ul className="option-list">
                  {Object.entries(pages).map(([page, arr]) => {
                    return (
                      arr[0] === ''
                        ?
                        <li key={page} className={`option-list-item flex-container flex-start rounded-radius p-l-1 m-t-1 default-cursor ${pageProp.toUpperCase() === page.toUpperCase() ? 'selected-option' : ''}`}>
                          <img src={arr[1]} alt="" />
                          <p className=' padding-half'>{page}</p>
                        </li>
                        :
                        <Link to={arr[0]} key={page}>
                          <li className={`option-list-item flex-container flex-start rounded-radius p-l-1 ${pageProp.toUpperCase() === page.toUpperCase() ? 'selected-option' : ''}`}>
                            <img src={arr[1]} alt="" />
                            <p className=' padding-half'>{page}</p>
                          </li>
                        </Link>
                    );
                  })}
                </ul>
            }
          </div>
          <ul id='workspace-ul' className="option-list current-workspace">
            {
              hidePopup ? '' :
                <li id='workspace-info' className="companies-container rounded-radius">
                  <ul>
                    <Link to={`/workspace-info/${selectedWorkspace.uniCode}`}>
                      <li className={`option-list-item flex-container flex-start rounded-radius p-l-1 ${pageProp.toUpperCase() == 'WORKSPACE' ? 'selected-option' : ''}`}>
                        <p className='upper-case padding-half'>Workspace Info</p>
                      </li>
                    </Link>
                    {
                      selectedWorkspaceId && selectedWorkspaceId > 0 ?
                        <Link to={`/collaborator/${selectedWorkspace.uniCode}`}>
                          <li className={`option-list-item flex-container flex-start rounded-radius p-l-1 ${pageProp.toUpperCase() == 'COLLABORATOR' ? 'selected-option' : ''}`}>
                            <p className='upper-case padding-half'>Collaborators</p>
                          </li>
                        </Link>
                        :
                        <li className={`option-list-item flex-container flex-start rounded-radius p-l-1 default-cursor ${pageProp.toUpperCase() == 'COLLABORATOR' ? 'selected-option' : ''}`}>
                          <p className='upper-case padding-half'>Collaborators</p>
                        </li>
                    }
                    <li className={`option-list-item flex-container flex-start rounded-radius p-l-1 default-cursor ${pageProp.toUpperCase() == 'PLAN AND BILLING' ? 'selected-option' : ''}`}>
                      <p className='upper-case padding-half'>Plan and Billing</p>
                    </li>
                  </ul>
                </li>
            }
            <li className={`option-list-item flex-container flex-start rounded-radius`} onClick={accordionClick}>
              <img src={selectedWorkspace ? process.env.REACT_APP_Laravel_API_URL_STRUCTURE + 'storage/' + selectedWorkspace.image : Gray} alt="logo" className='landing-menu-logo' />
              {/* <img src={TTP} alt="" /> */}
              <p className='upper-case'>{selectedWorkspace && selectedWorkspace.uniCode == "R6AmEkD4H" ? selectedWorkspace.name + ' - WORKSPACE' : 'NO WORKSPACE'}</p>
              <img src={Expand} alt="" style={{ height: '4rem' }} />
            </li>
          </ul>

        </div >
      }
    </div >
  )
}

export default LandingMenu