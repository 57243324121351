import { createBrowserRouter, RouteObject, Outlet } from 'react-router-dom';
import AirtableData from './components/airtable/AirtableData';
import RevenuesData from './components/airtable/RevenuesData';
import ExpensesData from './components/airtable/ExpensesData';
import PLTable from './components/airtable/PLTable';
import PPETable from './components/airtable/PPETable';
import BalanceSheet from './components/airtable/BalanceSheet';
import CashFlowReport from './components/airtable/CashFlowReport';
import FinancialStatements from './components/airtable/FinancialStatements';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import ProtectedRoute from './components/auth/ProtectedRoute ';
import { Dashboard } from './components/Dashboard';
import Layout from './components/layouts/Layout'; // Import the Layout component
import Base from './components/airtable/Base';
import Companies from './components/Companies';
import Collaborator from './components/Collaborator';
import Report from './components/airtable/Report';
import WorkspaceInfo from './components/workspaces/WorkspaceInfo';
import User from './components/User';
import Invite from './components/Invite';
import UpdateUser from './components/UpdateUser';
import TokenExpired from './components/redirects/TokenExpired';
import NotFound404 from './components/redirects/NotFound404';
import ResetPassword from './components/ResetPassword';
import ForgotPassword from './components/auth/ForgotPassword';
import ConfirmRegistration from './components/auth/ConfirmRegistration';
import Invoice from './components/Invoices/Invoice';
import Invoices from './components/Invoices/Invoices';
import InvoiceIssued from './components/Invoices/InvoiceIssued';
import LegalInfo from './components/workspaces/LegalInfo';
import ChartOfAccounts from './components/ChartOfAccounts/ChartOfAccounts';


interface Role {
  id: number;
  name: string;
  roleSpecification: string;
}


let roleObject: Role | null = null;
const role = localStorage.getItem('role');

if (role) {
  try {
    roleObject = JSON.parse(role);
  } catch (error) {
    console.error('Error parsing role from localStorage from routes', error);
  }
}

const roleName = roleObject ? roleObject.name : '';


const routes: RouteObject[] = [
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <Companies />
      </ProtectedRoute>
    )
  },
  {
    path: '/workspace-info',
    element: (
      <ProtectedRoute>
        <WorkspaceInfo />
      </ProtectedRoute>
    )
  },
  {
    path: '/legal-info/:id',
    element: (
      <ProtectedRoute>
        <LegalInfo />
      </ProtectedRoute>
    )
  },
  {
    path: '/workspace-info/:id',
    element: (
      <ProtectedRoute>
        <WorkspaceInfo />
      </ProtectedRoute>
    )
  },
  {
    path: '/collaborator/',
    element: (
      <ProtectedRoute>
        <Collaborator role={roleName} />
      </ProtectedRoute>
    )
  },
  {
    path: '/collaborator/:workspaceId',
    element: (
      <ProtectedRoute>
        <Collaborator role={roleName} />
      </ProtectedRoute>
    )
  },
  {
    path: '/user/:userId',
    element: (
      <ProtectedRoute>
        <UpdateUser registerProp={false} />
      </ProtectedRoute>
    )
  },
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <Layout>
          <Outlet />
        </Layout>
      </ProtectedRoute>
    ),
    children: [
      // { path: '/', element: <Companies /> },
      { path: '/dashboard/:uniCode', element: <Dashboard /> },
      { path: '/create-invoice/:uniCodeParam', element: <Invoice qrCodeDisplay={false} /> },
      { path: '/:selectedWrokspaceUniCode/invoice/:uniCodeParam', element: <Invoice qrCodeDisplay={true} /> },
      { path: '/invoices/:uniCode', element: <Invoices /> },
      { path: 'bank-db', element: <AirtableData tableName='BANK DB' /> },
      // { path: 'rev-db', element: <AirtableData tableName='REV BASE 2' /> },
      // { path: 'exp-db', element: <AirtableData tableName='EXP DB' /> },
      { path: 'rev-db', element: <RevenuesData workspaceId='1' /> },
      { path: 'exp-db', element: <ExpensesData workspaceId='1' /> },
      { path: 'assets-db', element: <AirtableData tableName='ASSETS DB' /> },
      { path: 'bases', element: <Base /> },
      { path: 'reports', element: <Report /> },
      { path: 'profit-and-loss', element: <PLTable /> },
      { path: 'ppe-table', element: <PPETable /> },
      { path: 'balance-sheet', element: <BalanceSheet /> },
      { path: 'cash-flow', element: <CashFlowReport /> },
      { path: 'financial-statements', element: <FinancialStatements /> },
      { path: 'chart-of-accounts', element: <ChartOfAccounts /> },
    ]
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/register',
    element: <Register />
  },
  {
    path: '/forget-password',
    element: <ForgotPassword />
  },
  {
    path: '/reset-password',
    element: <ResetPassword />
  },
  {
    path: '/invite/:workspaceId',
    element: <UpdateUser registerProp={false} />
  },
  {
    path: '/confirm-registration',
    element: <ConfirmRegistration />
  },
  {
    path: '/token-expired',
    element: <TokenExpired />
  },
  {
    path: '/token-expired',
    element: <NotFound404 />
  },
  // {
  //   path: '/create-invoice',
  //   element: <Invoice />
  // },
];

const router = createBrowserRouter(routes);

export default router;

