import React from 'react'
import '../../css/base.css'
import { Link } from 'react-router-dom'
const Report = () => {
  return (
    <>
      <h1>Reports</h1>
      <hr />
      <div className="container1">
        <div className="row">
          {/* <div className="col-2">
            <Link to="/profit-and-loss" style={{ "textDecoration": "none" }}>
              <div className="square">
                <div className="square-content">
                  <h1>Profit And Loss</h1>
                  <p></p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-2">
            <Link to="/balance-sheet" style={{ "textDecoration": "none" }}>
              <div className="square">
                <div className="square-content">
                  <h1>Balance Sheet</h1>
                  <p></p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-2">
            <Link to="/ppe-table" style={{ "textDecoration": "none" }}>
              <div className="square">
                <div className="square-content">
                  <h1>PPE</h1>
                  <p></p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-2">
            <Link to="/cash-flow" style={{ "textDecoration": "none" }}>
              <div className="square">
                <div className="square-content">
                  <h1>Cash Flow</h1>
                  <p></p>
                </div>
              </div>
            </Link>
          </div> */}
          <div className="col-2">
            <Link to="/financial-statements" style={{ "textDecoration": "none" }}>
              <div className="square">
                <div className="square-content">
                  <h1>FS</h1>
                  <p></p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default Report