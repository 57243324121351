export const addCommaAndSeparator = (num: number | string) => {
  if (typeof num === 'string') {
    num = parseFloat(num);
  }
  return num.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export const removeSeparator = (num: string) => {
  return num.replace(/,/g, '');
}

export const removeDecimalPoint = (num: string) => {
  return num.replace(/\.00/g, '');
}

export const getCurrentDate = () => {
  const date = new Date();
  return date.toISOString().split('T')[0];
}

export const getMonthAbbreviation = (date: string) => {
  const month = new Date(date).getMonth();
  const monthAbbreviation = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return monthAbbreviation[month].toUpperCase();
}

export const getDay = (date: string) => {
  return new Date(date).getDate();
}

export const getYear = (date: string) => {
  return new Date(date).getFullYear();
}