import React, { useState, useEffect } from 'react';
import axios from 'axios';

const token = localStorage.getItem('token');
const ChartOfAccounts = () => {
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAccounts = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/chart-of-accounts`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    }
                });
                setAccounts(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching accounts:', error);
            }
        };

        fetchAccounts();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    const renderAccounts = (accounts, level = 0) => {
        return accounts.map((account) => (
            <div key={account.id} style={{ marginLeft: `${level * 20}px` }}>
                <strong>{account.account_code}</strong> - {account.name}
                {account.children_recursive && account.children_recursive.length > 0 && (
                    <div>{renderAccounts(account.children_recursive, level + 1)}</div>
                )}
            </div>
        ));
    };

    return (
        <div>
            <h1>Chart of Accounts</h1>
            {renderAccounts(accounts)}
        </div>
    );
};

export default ChartOfAccounts;
