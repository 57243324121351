import React, { useEffect, useState } from 'react'
import InvoiceIssuedIcon from '../../assets/InvoiceIssued.svg'
import Share from '../../assets/Share.svg'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { addCommaAndSeparator } from '../../utilities/helper';
import QRCode from 'qrcode.react';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';

interface Props {
  invoiceNumber: string;
  issueDate: string;
  clientName: string;
  invoiceTotal: string;
  separatePage: boolean;
  showQRCode: boolean;
  invoiceUrl: string;
}

const InvoiceIssued: React.FC<Props> = ({ invoiceNumber, issueDate, clientName, invoiceTotal, separatePage, showQRCode, invoiceUrl }) => {
  const { uniCode } = useParams<{ uniCode: string }>();
  const token = localStorage.getItem('token');
  const [isLoading, setIsLoading] = useState(true);
  const [invoiceUrlState, setInvoiceUrlState] = useState('');
  const selectedWorkspace = useSelector((state: RootState) => state.workspaceState.selectedWorkspace);
  useEffect(() => {
    if (invoiceUrl) {
      setInvoiceUrlState(invoiceUrl)
    }
  }, [invoiceUrl]);
  const fetchInvoice = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/get-success-invoice/${uniCode}`;
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      });
      const invoiceResponse = response.data.invoice;
      setInvoiceUrlState(process.env.REACT_APP_WEB_URL + selectedWorkspace.uniCode + "/invoice/" + invoiceResponse.invoice_number)
      document.getElementById('invoice-number')!.textContent = invoiceResponse.invoice_number;
      document.getElementById('issue-date')!.textContent = invoiceResponse.issue_date;
      document.getElementById('client-name')!.textContent = invoiceResponse.client_name;
      document.getElementById('total_after_vat')!.textContent = `SAR ${addCommaAndSeparator(response.data.total_after_vat)}`;
      // alert(response.data.invoice_total)
      setIsLoading(false);
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    if (separatePage) {
      fetchInvoice()
    }
  }, []);

  return (
    <div className='invoice-issued-container position-relative'>
      {separatePage && isLoading ? <p className="bold-text" style={
        {
          'color': '#94ddf3',
          'display': 'inline-block',
          'marginLeft': '1rem',
          'fontSize': '2rem',
          'position': 'fixed',
          'top': '1rem',
        }}>
        Loading...
      </p> : ""}
      <div className={separatePage ? 'invoice-issued-flex-container' : 'invoice-issued-flex-container-big'}>
        <div id="main-box" className="position-relative">
          <div className="flex-container">
            <div className='equal-flex-side'></div>
            <div id='invoice-issued-middle-header' className="flex-container flex-vertical-direction equal-flex-side">
              <img src={InvoiceIssuedIcon} alt="invoice-icon" />
              <div className='position-relative tax-invoice-issued'>
                <p>
                  <span className='bold-text'>
                    TAX INVOICE
                  </span> ISSUED
                </p>
              </div>
              <p id='total_after_vat'>
                SAR {invoiceTotal}
              </p>
            </div>
            <div className='share-container equal-flex-side'>
              <img src={Share} alt="share" className='float-right m-r-1' />
            </div>
          </div>

          <div className="invoice-issued-body m-t-3">
            <div className='flex-container flex-space-between m-t-1 position-relative'>
              <p>Invoice Number</p>
              {showQRCode && (
                <div className="issued_qr_code_container">
                  <QRCode value={invoiceUrlState} size={90} />
                </div>
              )}
              <p id="invoice-number">{invoiceNumber}</p>
            </div>
            <div className='flex-container flex-space-between m-t-1'>
              <p>Invoice Date</p>
              <p id="issue-date">{issueDate}</p>
            </div>
            <div className='flex-container flex-space-between m-t-1'>
              <p>Customer</p>
              <p id="client-name">{clientName}</p>
            </div>
            <div className='flex-container flex-space-between m-t-1'>
              <p>Company</p>
              <p id="invoice-number">Demo</p>
            </div>
            <div className="flex-container flex-center download-invoice-container">
              <button className='grassy-button' id='download-invoice'>Download Invoice</button>
            </div>
          </div>
        </div>
        <div className="invoice-issued-details flex-container flex-space-around m-t-1">
          <p>POWERED BY <span className='bold-text'>SEJEL</span></p>
          <p>Terms</p>
          <p>Privacy</p>
        </div>
      </div>
    </div>
  )
}

export default InvoiceIssued