import React from 'react'
import '../../css/alert.css'
interface AlertProps {
  body: string;
  status: string;
}

const Alert: React.FC<AlertProps> = ({ body, status }) => {
  return (
    <div className={`rounded-radius alert-message ${status}`}>
      {body}
    </div>
  )
}

export default Alert