import React from 'react';
import { AirtableRecord } from '../../types/airtable'; // Ensure this is correctly defined
import styles from '../../css/AirtableData.module.css'; // Adjust path as necessary

interface DepreciationPopupProps {
  record: AirtableRecord;
  onClose: () => void;
}

const DepreciationPopup: React.FC<DepreciationPopupProps> = ({ record, onClose }) => {
    if (!record) return null;

    // Extract the starting year from the DATE field
    const startingYear = new Date(record.fields['DATE'] as string).getFullYear();

    return (
        <div className={styles.modal}>
            <div className={styles.modalContent}>
                <div className={styles.modalHeader}>
                    <h2>Depreciation Details for {record.fields['REF']}</h2>
                    {/* <button onClick={onClose} className={styles.closeButton}>&times;</button> */}
                </div>
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>Year</th>
                                <th>Depreciated Cost</th>
                                <th>Depreciated Value</th>
                                <th>Residual Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {[...Array(5)].map((_, index) => {
                                const year = startingYear + index + 1;  // Use the extracted year
                                const initialAmount = parseFloat(record.fields['AMOUNT'] as string);
                                const depreciationCost = initialAmount * 0.2;
                                const depreciatedValue = initialAmount * 0.2 * (index + 1);
                                const residualValue = (initialAmount - depreciatedValue);
                                return (
                                    <tr key={index}>
                                        <td>{year}</td>
                                        <td>{depreciationCost.toFixed(2)}</td>
                                        <td>{depreciatedValue.toFixed(2)}</td>
                                        <td>{residualValue.toFixed(2)}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className={styles.modalFooter}>
                    <button onClick={onClose} className={styles.closeButton}>Close</button>
                </div>
            </div>
        </div>
    );
};

export default DepreciationPopup;
