// import React, { useState } from 'react';
// import '../../css/FinancialReportPopup.css';
// import PLTable from '../airtable/PLTable';
// import BalanceSheet from '../airtable/BalanceSheet';
// import CashFlow from '../airtable/CashFlowReport';

import React, { useState } from 'react';
import '../../css/FinancialReportPopup.css';
import PLTable from '../airtable/PLTable';
import BalanceSheet from '../airtable/BalanceSheet';
import CashFlow from '../airtable/CashFlowReport';

interface FinancialReportPopupProps {
  onClose: () => void;
}

const FinancialReportPopup: React.FC<FinancialReportPopupProps> = ({ onClose }) => {
  const [activeReport, setActiveReport] = useState<string | null>(null);

  const handleReportClick = (report: string) => {
    setActiveReport(report);
  };

  return (
    <div className="financial-report-popup open">
      <div className="financial-report-popup-content">
        <div className="financial-report-popup-header">
          <h2>Financial Report</h2>
          <button className="close-button" onClick={onClose}>X</button>
        </div>
        <table className="financial-report-table">
          <thead>
            <tr>
              <th>FINANCIAL REPORT</th>
              <th>FY.20</th>
              <th>FY.21</th>
              <th>FY.22</th>
              <th>FY.23</th>
            </tr>
          </thead>
          <tbody>
            <tr onClick={() => handleReportClick('ProfitLoss')}>
              <td colSpan={5}>PROFIT & LOSS</td>
            </tr>
            <tr onClick={() => handleReportClick('BalanceSheet')}>
              <td colSpan={5}>BALANCE SHEET</td>
            </tr>
            <tr onClick={() => handleReportClick('CashFlow')}>
              <td colSpan={5}>CASH FLOW</td>
            </tr>
          </tbody>
        </table>




        {activeReport === 'ProfitLoss' && <PLTable />}
        {activeReport === 'BalanceSheet' && <BalanceSheet />}
        {activeReport === 'CashFlow' && <CashFlow />}
      </div>
    </div>
  );
};

export default FinancialReportPopup;