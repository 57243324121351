import React from 'react';
import { Navigate } from 'react-router-dom';

interface WithAuthorizationProps {
    role: string;
}

const withAuthorization = <P extends object>(
    WrappedComponent: React.ComponentType<P>,
    allowedRoles: string[] = [] // Provide default value as empty array
) => {
    return (props: P & WithAuthorizationProps) => {
        const { role } = props;

        if (!allowedRoles.includes(role)) {
            return <Navigate to="/not-authorized" />;
        }

        return <WrappedComponent {...props} />;
    };
};

export default withAuthorization;
