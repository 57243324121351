import React from 'react';
import { Provider } from 'react-redux';
import { store, persistor } from './store/index';
import { RouterProvider } from 'react-router-dom';
import router from './routes';
import './css/variables.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/Main.css'; // Import the CSS file
import './css/fonts.css'; // Import the CSS file
import { PersistGate } from 'redux-persist/integration/react';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  );
};

export default App;
