import React from 'react'
import './../css/workspace.css'
import LandingMenu from './landing/LandingMenu'
import LandingSection from './landing/LandingSection'
import companies from './../assets/companies.svg'
import dataSource from './../assets/data-source.svg'

const Companies = () => {
  const pages = {
    'Companies': ['', companies],
    'Data Source': ['', dataSource],
  }

  return (
    <div className="main-container flex-container">
      <LandingMenu pages={pages} hidePopup={false} hidePanelContents={false} pageProp='companies' />
      <LandingSection />

    </div>
  )
}

export default Companies