import React from 'react'
import InfoSquared from '../../assets/InfoSquared.svg'
import Files from '../../assets/Files.svg'
import BankBuilding from '../../assets/BankBuilding.svg'
import Address from '../../assets/Address.svg'
import '../../css/WorkspaceIconPanel.css'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

interface Props {
    page: string;
}

const WorkspaceIconPanel: React.FC<Props> = ({ page }) => {
    const selectedWorkspace = useSelector((state: RootState) => state.workspaceState.selectedWorkspace);
    return (
        <div id="workspace-icon-container" className='left-panel'>
            <div className="icon-container">
                <Link to={`/workspace-info/${selectedWorkspace.uniCode}`}>
                    <img src={InfoSquared} alt="workspace icon" className={`${page === 'workspace' ? 'workspace-icon-active' : ''} pointer clickable`} />
                    <span className="hover-label">Info</span>
                </Link>
            </div>
            <div className="icon-container">
                <Link to={`/legal-info/${selectedWorkspace.uniCode}`}>
                    <img src={Files} alt="workspace icon" className={`${page === 'files' ? 'workspace-icon-active' : ''} pointer clickable`} />
                    <span className="hover-label">Files</span>
                </Link>
            </div>
            <div className="icon-container">
                <Link to={`/workspace-info/${selectedWorkspace.uniCode}`}>
                    <img src={BankBuilding} alt="workspace icon" className={`${page === 'bankBuilding' ? 'workspace-icon-active' : ''} pointer clickable`} />
                    <span className="hover-label">Banks</span>
                </Link>
            </div>
            <div className="icon-container">
                <Link to={`/workspace-info/${selectedWorkspace.uniCode}`}>
                    <img src={Address} alt="workspace icon" className={`${page === 'address' ? 'workspace-icon-active' : ''} pointer clickable`} />
                    <span className="hover-label">Address</span>
                </Link>
            </div>
        </div>
    )
}

export default WorkspaceIconPanel