import React from 'react';
import '../../css/PLTable.css';

const cashFlowData = {
  netProfit: {
    "2020": 271902,
    "2021": 705827,
    "2022": 5201796,
    "2023": 4054794
  },
  addBackDepreciation: {
    "2020": 22321,
    "2021": 15766,
    "2022": 15933,
    "2023": 16453
  },
  addBackEOSI: {
    "2020": 45041,
    "2021": 99786,
    "2022": 0,
    "2023": 855149
  },
  changeInPayables: {
    "2020": 147956,
    "2021": 283812,
    "2022": -72301,
    "2023": 281976
  },
  changeInReceivables: {
    "2020": -579259,
    "2021": -1167578,
    "2022": -4889930,
    "2023": -3951853
  },
  netCashFromOperations: {
    "2020": -92039,
    "2021": -62387,
    "2022": 255497,
    "2023": 1256518
  },
  investmentsInPPE: {
    "2020": -76132,
    "2021": 0,
    "2022": -833,
    "2023": -2601
  },
  netCashFromInvestments: {
    "2020": -76132,
    "2021": 0,
    "2022": -833,
    "2023": -2601
  },
  changeInCapital: {
    "2020": 100000,
    "2021": 0,
    "2022": 0,
    "2023": 0
  },
  changeInRelatedParties: {
    "2020": 297848,
    "2021": 149651,
    "2022": -479649,
    "2023": -575267
  },
  netCashFromFinancing: {
    "2020": 397848,
    "2021": 149651,
    "2022": -479649,
    "2023": -575267
  },
  netCashFlow: {
    "2020": 229677,
    "2021": 87264,
    "2022": -224985,
    "2023": 678650
  },
  cumulativeCashFlow: {
    "2020": 229677,
    "2021": 316941,
    "2022": 91956,
    "2023": 770606
  },
};

const CashFlowReport: React.FC = () => {
  const years = ["2020", "2021", "2022", "2023"];

  const formatNumber = (value: any) => {
    return new Intl.NumberFormat('en-US').format(value.toFixed(2));
  };

  return (
    <div className="table-container">
      <h1>Cash Flow Report</h1>
      <table className="cash-flow-table">
        <thead>
          <tr>
            <th>Category</th>
            {years.map((year) => (
              <th key={year}>{year}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Net Profit</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(cashFlowData.netProfit[year])}</td>
            ))}
          </tr>
          <tr>
            <td>Add Back Depreciation</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(cashFlowData.addBackDepreciation[year])}</td>
            ))}
          </tr>
          <tr>
            <td>Add Back EOSI</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(cashFlowData.addBackEOSI[year])}</td>
            ))}
          </tr>
          <tr>
            <td>Change in Payables</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(cashFlowData.changeInPayables[year])}</td>
            ))}
          </tr>
          <tr>
            <td>Change in Receivables</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(cashFlowData.changeInReceivables[year])}</td>
            ))}
          </tr>
          <tr>
            <td><b>Net Cash from Operations</b></td>
            {years.map((year) => (
              <td key={year}>{formatNumber(cashFlowData.netCashFromOperations[year])}</td>
            ))}
          </tr>
          <tr><td colSpan={years.length + 1}>&nbsp;</td></tr>
          <tr>
            <td>Investments in PPE</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(cashFlowData.investmentsInPPE[year])}</td>
            ))}
          </tr>
          <tr>
            <td><b>Net Cash from Investments</b></td>
            {years.map((year) => (
              <td key={year}>{formatNumber(cashFlowData.netCashFromInvestments[year])}</td>
            ))}
          </tr>
          <tr><td colSpan={years.length + 1}>&nbsp;</td></tr>
          <tr>
            <td>Change in Capital</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(cashFlowData.changeInCapital[year])}</td>
            ))}
          </tr>
          <tr>
            <td>Change in Related Parties</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(cashFlowData.changeInRelatedParties[year])}</td>
            ))}
          </tr>
          <tr>
            <td><b>Net Cash from Financing</b></td>
            {years.map((year) => (
              <td key={year}>{formatNumber(cashFlowData.netCashFromFinancing[year])}</td>
            ))}
          </tr>
          <tr><td colSpan={years.length + 1}>&nbsp;</td></tr>
          <tr>
            <td><b>Net Cash Flow</b></td>
            {years.map((year) => (
              <td key={year}>{formatNumber(cashFlowData.netCashFlow[year])}</td>
            ))}
          </tr>
          <tr>
            <td><b>Cumulative Cash Flow</b></td>
            {years.map((year) => (
              <td key={year}>{formatNumber(cashFlowData.cumulativeCashFlow[year])}</td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CashFlowReport;
