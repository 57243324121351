import React, { useEffect } from 'react';
import Navbar from './Navbar';
import RightNavbar from './RightNavbar';
import '../../css/layout.css';
import Menu from '../Menu';
interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  useEffect(() => {
    document.title = "Sejel.io ";
  }, []);
  return (
    <div className="app-container">
      <Menu />
      <Navbar />
      <RightNavbar />
      <div id='main-side' className="content-container">
        {children}
      </div>
    </div>
  );
};

export default Layout;
