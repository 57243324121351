import React, { useState } from 'react';
import axios from 'axios';
import Alert from '../shapes/Alert';

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const alertBody = 'Request sent successfully! Check your email for the reset link.';
  const [alertStatus, setAlertStatus] = useState('');

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      setLoading(true)
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/forget-password`, { email });
      setMessage(response.data.message);
      setAlertStatus('success');
      setShowAlert(true);
    } catch (error) {
      setMessage('Something went wrong. Please try again.');
      setShowAlert(true);
      setAlertStatus('error');
    } finally {
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
      setLoading(false);
    }
  };

  return (
    <div className="main-container flex-container">
      <div>
        {showAlert ? <Alert body={alertBody} status={alertStatus} /> : ""}
      </div>
      <div id="workspace-info-side" className='main-side flex-10'>
        <div id="user-section" className="flex-container flex-space-between">
          <div id="title">
            <h3 className='bold-text'>Forgot Password</h3>
            <p>Change your password</p>
          </div>
          {/* <UserCircle /> */}
        </div>
        <div className="form-box bg-white rounded-radius w-50 m-t-5 pd-1">
          <form id='workspace-form' method="post" onSubmit={handleSubmit}>
            <input type="email" name='email' placeholder='email' className={`workspace-input rounded-radius`} onChange={(e) => setEmail(e.target.value)} />
            <input type="submit" value="Send Reset Link" className='submit-button rounded-radius m-t-1' />
            {
              loading ? <p className="bold-text" style={
                {
                  'color': '#94ddf3',
                  'display': 'inline-block',
                  'marginLeft': '1rem',
                  'fontSize': '2rem',
                  'top': '4px',
                  'position': 'relative',
                }}>
                Loading...
              </p> : ""
            }
            {/* {message && <p>{message}</p>} */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
